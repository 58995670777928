import Link from "next/link"
import { PropsWithChildren } from "react"
import { twMerge } from "tailwind-merge"

type Props = {
    href: string
    className?: string
}

export default function SecondaryLinkButton({
    href,
    className,
    children,
}: PropsWithChildren<Props>) {
    return (
        <Link
            href={href}
            className={twMerge(
                `flex h-10 items-center justify-center rounded-md bg-white px-3 py-2 text-sm
                font-semibold text-primary-600 shadow-sm ring-1 ring-inset ring-gray-300
                hover:bg-stone-200`,
                className ?? "",
            )}
        >
            {children}
        </Link>
    )
}
