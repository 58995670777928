import { z } from "zod"

const adminEmail = z
    .string()
    .email()
    .endsWith(
        "@accessly.com.au",
        "Registration only permitted to administrators",
    )

const passwordString = z
    .string({
        invalid_type_error:
            "The password length must be between 4 and 20 characters",
    })
    .min(4)
    .max(20)

export const userSchemas = {
    update: z.object({
        userId: z.string(),
        name: z.string().min(1).max(100),
        email: z.string().email(),
        phone: z.string(),
    }),

    password: z.object({
        password: passwordString,
    }),

    email: z.object({
        email: z.string().email(),
    }),

    signIn: z.object({
        email: z.string().email(),
        password: passwordString,
    }),

    register: z.object({
        name: z.string().min(1).max(100),
        email: adminEmail,
        password: passwordString,
    }),

    changePassword: z
        .object({
            password: passwordString,
            newPassword: passwordString,
            confirmNewPassword: z.string().min(1, "Please Confirm Password"),
        })
        .refine((data) => data.newPassword === data.confirmNewPassword, {
            message: "Passwords don't match",
            path: ["confirmNewPassword"],
        }),
}
