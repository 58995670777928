"use client"

import { PropsWithChildren } from "react"
import { useZodForm } from "~/app/_components/form/form"
import { userSchemas } from "~/shared/userSchemas"
import { SignInContext } from "./SignInContext"

type Props = {}

export default function SignInLayout(props: PropsWithChildren<Props>) {
    const layoutForm = useZodForm({
        schema: userSchemas.email,
    })

    return (
        <SignInContext.Provider
            value={{
                layoutForm,
            }}
        >
            {props.children}
        </SignInContext.Provider>
    )
}
