"use client"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { createTRPCProxyClient, httpLink, loggerLink } from "@trpc/client"
import { createTRPCReact } from "@trpc/react-query"
import { createContext, useState } from "react"

import { type AppRouter } from "~/server/routers"
import { getUrl, transformer } from "./shared"

const sessionId = crypto.randomUUID()

export const api = createTRPCReact<AppRouter>()

export const TrpcClientContext = createContext(
    createTRPCProxyClient<AppRouter>({
        transformer,
        links: [
            loggerLink({
                enabled: () => process.env.NEXT_PUBLIC_ACCESSLY_ENV === "local",
            }),
            httpLink({
                url: getUrl(),
                headers() {
                    return {
                        "x-session-id": sessionId,
                        "x-trpc-source": "react",
                    }
                },
            }),
        ],
    }),
)

export function TrpcReactProvider(props: {
    children: React.ReactNode
    cookies: string
}) {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                    },
                },
            }),
    )

    const [trpcClient] = useState(() =>
        api.createClient({
            transformer,
            links: [
                loggerLink({
                    enabled: () =>
                        process.env.NEXT_PUBLIC_ACCESSLY_ENV === "local",
                }),
                httpLink({
                    url: getUrl(),
                    headers() {
                        return {
                            cookie: props.cookies,
                            "x-session-id": sessionId,
                            "x-trpc-source": "react",
                        }
                    },
                }),
            ],
        }),
    )

    const [proxyClient] = useState(() =>
        createTRPCProxyClient<AppRouter>({
            transformer,
            links: [
                loggerLink({
                    enabled: () =>
                        process.env.NEXT_PUBLIC_ACCESSLY_ENV === "local",
                }),
                httpLink({
                    url: getUrl(),
                    headers() {
                        return {
                            cookie: props.cookies,
                            "x-session-id": sessionId,
                            "x-trpc-source": "react",
                        }
                    },
                }),
            ],
        }),
    )

    return (
        <TrpcClientContext.Provider value={proxyClient}>
            <QueryClientProvider client={queryClient}>
                <api.Provider client={trpcClient} queryClient={queryClient}>
                    {props.children}
                </api.Provider>
            </QueryClientProvider>
        </TrpcClientContext.Provider>
    )
}
