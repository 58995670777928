"use client"

import { PropsWithChildren } from "react"

type Props = {
    title: string
}

export default function CenteredFormContainer(props: PropsWithChildren<Props>) {
    if (props.title) {
        return (
            <div className="flex min-h-full w-full flex-1 flex-col justify-center sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        {props.title}
                    </h2>
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        {props.children}
                    </div>
                </div>
            </div>
        )
    } else {
        return <div>{props.children}</div>
    }
}
