/* eslint @typescript-eslint/no-unsafe-assignment: 0 */
/* eslint @typescript-eslint/no-unsafe-member-access: 0 */

export default function deepGet<R>(
    obj: any,
    query: string | string[],
    defaultVal?: R,
): R | undefined {
    query = Array.isArray(query)
        ? query
        : query
              .replace(/(\[(\d)\])/g, ".$2")
              .replace(/^\./, "")
              .split(".")

    if (!query[0] || !(query[0] in obj)) {
        return defaultVal
    }

    obj = obj[query[0]]
    if (obj && query.length > 1) {
        return deepGet(obj, query.slice(1), defaultVal)
    }

    return obj as unknown as R
}
