"use client"

import { createContext, useContext } from "react"
import { z } from "zod"
import { UseZodForm } from "~/app/_components/form/form"
import { userSchemas } from "~/shared/userSchemas"

export const SignInContext = createContext<
    | {
          layoutForm: UseZodForm<z.infer<typeof userSchemas.email>>
      }
    | undefined
>(undefined)

export const useSignInContext = () => {
    const val = useContext(SignInContext)
    if (!val) {
        throw Error("Invalid context")
    }

    return val
}
