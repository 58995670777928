import { useToastDispatchContext } from "../_components/toast/ToastContext"

type Toast = {
    message: string
    type: "success" | "error" | "warning" | "info"
}

export function useToast() {
    const dispatch = useToastDispatchContext()

    function toast({ type, message }: Toast) {
        const id = Math.random()
        dispatch({
            type: "ADD_TOAST",
            toast: {
                type,
                message,
                id,
            },
        })

        setTimeout(() => {
            dispatch({ type: "DELETE_TOAST", id })
        }, 3000)
    }

    return toast
}
