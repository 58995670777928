import { useSession } from "next-auth/react"
import { useRouter } from "next/navigation"
import { useEffect, useState } from "react"

export default function useSignInRedirect() {
    const [pendingRedirect, setPendingRedirect] = useState(false)

    const router = useRouter()
    const session = useSession()
    useEffect(() => {
        if (session.data?.user && pendingRedirect) {
            const user = session.data.user
            if (user.customerId) {
                router.push(`/customer/${user.customerId}/visits`)
            } else if (user.companyId) {
                router.push(`/company/${user.companyId}`)
            } else {
                router.push("/admin/customers")
            }
        }
    }, [session, pendingRedirect, router])

    return [pendingRedirect, setPendingRedirect] as const
}
