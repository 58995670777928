export default function LogoStyled() {
    return (
        <>
            <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_i_378_12470)">
                    <rect width="48" height="48" rx="10" fill="#D9D9D9" />
                    <rect
                        width="48"
                        height="48"
                        rx="10"
                        fill="url(#paint0_linear_378_12470)"
                    />
                </g>
                <g filter="url(#filter1_dd_378_12470)">
                    <path
                        d="M11 25.765C11 18.9111 16.5288 14.0098 23.0192 14.0098C27.1458 14.0098 29.109 15.6834 30.2308 17.5164H30.6314L31.3926 14.5278H36L32.4744 34.4519H27.867L28.1074 31.7024H27.7067C26.1442 33.6151 23.6202 35.0098 20.2949 35.0098C14.9663 35.0098 11 31.5031 11 25.765ZM16.0881 25.5657C16.0881 29.1122 18.4119 30.8257 21.9776 30.8257C26.2244 30.8257 29.5497 27.3988 29.5497 23.1749C29.5497 20.1862 27.3462 18.1938 23.7003 18.1938C19.2532 18.1938 16.0881 21.1027 16.0881 25.5657Z"
                        fill="#FFFDFF"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_i_378_12470"
                        x="0"
                        y="0"
                        width="48"
                        height="50"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="1" />
                        <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                        />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect1_innerShadow_378_12470"
                        />
                    </filter>
                    <filter
                        id="filter1_dd_378_12470"
                        x="1"
                        y="2.00977"
                        width="45"
                        height="46"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="3" />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_378_12470"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="-4" />
                        <feGaussianBlur stdDeviation="4" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="effect1_dropShadow_378_12470"
                            result="effect2_dropShadow_378_12470"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect2_dropShadow_378_12470"
                            result="shape"
                        />
                    </filter>
                    <linearGradient
                        id="paint0_linear_378_12470"
                        x1="24"
                        y1="0"
                        x2="24"
                        y2="48"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#774AF4" />
                        <stop offset="1" stopColor="#5421E2" />
                    </linearGradient>
                </defs>
            </svg>
        </>
    )
}
