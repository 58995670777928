"use client"

import { PropsWithChildren } from "react"
import LogoStyled from "../_components/icons/logo"

export default function AuthLayout(props: PropsWithChildren) {
    return (
        <div className="flex flex-col pt-12">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="mx-auto flex h-10 w-auto items-center justify-center">
                    <LogoStyled />
                </div>
            </div>
            {props.children}
        </div>
    )
}
