"use client"

import { createContext, useContext, useReducer } from "react"

const ToastStateContext = createContext<{ toasts: Toast[] }>({ toasts: [] })
const ToastDispatchContext = createContext<React.Dispatch<ToastAction>>(
    function () {
        // do nothing
    },
)

export type Toast = {
    id: number
    message: string
    type: "success" | "error" | "warning" | "info"
}

type ToastState = {
    toasts: Toast[]
}

type ToastAction =
    | { type: "ADD_TOAST"; toast: Toast }
    | { type: "DELETE_TOAST"; id: number }

function ToastReducer(state: ToastState, action: ToastAction): ToastState {
    switch (action.type) {
        case "ADD_TOAST": {
            return {
                ...state,
                toasts: [...state.toasts, action.toast],
            }
        }
        case "DELETE_TOAST": {
            const updatedToasts = state.toasts.filter((e) => e.id !== action.id)
            return {
                ...state,
                toasts: updatedToasts,
            }
        }
        default: {
            throw new Error("Unhandled action type")
        }
    }
}

export function ToastProvider({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(ToastReducer, {
        toasts: [],
    })
    return (
        <ToastStateContext.Provider value={state}>
            <ToastDispatchContext.Provider value={dispatch}>
                {children}
            </ToastDispatchContext.Provider>
        </ToastStateContext.Provider>
    )
}

export const useToastDispatchContext = () => useContext(ToastDispatchContext)

export const useToastStateContext = () => useContext(ToastStateContext)
